<template>
  <div class="admin-create-user">
    <loading-screen :is-loading="isSaving"></loading-screen>

    <!-- Page Header -->
    <div class="page-header">
      <h1 class="page-title">
        Create New User For {{ selectedOrganisation.displayName }}
      </h1>
      <div class="actions">
        <a-button
          class="button-margin-left btn-rounded"
          icon="arrow-left"
          size="large"
          type="primary"
          @click.prevent="goBack"
          >Back</a-button
        >
      </div>
    </div>
    <!-- / Page Header -->

    <!-- Is Loading -->
    <div v-if="isLoading" class="loader">
      <a-spin></a-spin>
    </div>
    <!-- / Is Loading -->

    <!-- Form -->
    <div v-if="!isLoading" class="form">
      <!-- Top Row -->
      <div class="top-row">
        <div class="left">
          <a-form-item label="Photo">
            <a-upload
              :multiple="false"
              :file-list="fileList"
              :before-upload="beforeUpload"
              :remove="handleRemove"
            >
              <a-button>
                <a-icon type="upload"></a-icon>
                Click to Upload
              </a-button>
            </a-upload>
          </a-form-item>
        </div>
        <div class="right">
          <a-row :gutter="20">
            <a-col :span="12">
              <a-form-item label="First Name">
                <a-input size="large" v-model="userLocal.firstname"></a-input>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="Surname">
                <a-input size="large" v-model="userLocal.surname"></a-input>
              </a-form-item>
            </a-col>
          </a-row>
        </div>
      </div>
      <!-- / Top Row -->

      <!-- Email -->
      <a-form-item label="Email">
        <a-input size="large" v-model="userLocal.email"></a-input>
      </a-form-item>
      <!-- / Email -->

      <!-- Alt Email -->
      <a-form-item label="Alternative Email (Where Differs To Username)">
        <a-input size="large" v-model="userLocal.altEmail"></a-input>
      </a-form-item>
      <!-- / Alt Email -->

      <a-form-item
        style="margin-bottom: 0"
        label="Add To Response Teams"
      ></a-form-item>

      <response-team-selector
        :tenant-id="tenantId"
        :org-id="selectedOrganisation.id"
        :selected-response-team-ids="responseTeamIds"
        @toggle-response-team="toggleResponseTeamSelected"
      ></response-team-selector>

      <a-form-item
        style="margin-top: 15px; margin-bottom: 0"
        label="Permission"
      ></a-form-item>

      <user-permissions-selector
        :permissions="userLocal.permissions"
        @set-permissions="setPermissions"
      ></user-permissions-selector>

      <a-form-item
        style="margin-top: 25px; margin-bottom: 0;"
        label="Access Expires"
      >
      <a-date-picker show-time size="large" style="width: 100%" v-model="userLocal.accessExpires" />

      </a-form-item>
    </div>
    <!-- / Form -->

    <a-button
      @click.prevent="save"
      class="btn-rounded save-button"
      size="large"
      type="primary"
      >Save</a-button
    >
  </div>
</template>

<script>
const _ = require("lodash");
import { mapGetters } from "vuex";
import LoadingScreen from "../../../../components/LoadingScreen.vue";
import ResponseTeamSelector from "../../../../components/Admin/ResponseTeamSelector.vue";
import UserPermissionsSelector from "../../../../components/Admin/UserPermissionSelector.vue";
import presences from "../../../../api/presences";
import axios from 'axios';
export default {
  components: { LoadingScreen, ResponseTeamSelector, UserPermissionsSelector },

  data() {
    return {
      isSaving: false,
      fileList: [],
      userLocal: {
        firstname: "",
        surname: "",
        email: "",
        altEmail: "",
        permissions: 0,
        accessExpires: null
      },
      responseTeamIds: [],
    };
  },

  methods: {
    goBack() {
      this.$router.push("/admin/users/manage");
    },

    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },

    beforeUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error("Please upload a JPG or a PNG");
      }
      if (isJpgOrPng) {
        this.fileList = [file];
      }
      return false;
    },

    toggleResponseTeamSelected(responseTeamId) {
      this.responseTeamIds = _.xor(this.responseTeamIds, [responseTeamId]);
    },

    setPermissions(permissions) {
      this.userLocal.permissions = permissions;
    },

    validateEmail(email) {
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
    },

    getValidationErrors() {
      let errors = [];
      if (this.userLocal.firstname.trim().length == 0) {
        errors.push("Please provide a first name");
      }
      if (this.userLocal.surname.trim().length == 0) {
        errors.push("Please provide a surname");
      }
      if (this.userLocal.email.trim().length == 0) {
        errors.push("Please provide an email");
      }
      if (!this.validateEmail(this.userLocal.email)) {
        errors.push(this.userLocal.email + " is not a valid email");
      }
        if (this.userLocal.altEmail.trim().length && !this.validateEmail(this.userLocal.altEmail)) {
        errors.push(this.userLocal.altEmail + " is not a valid email");
      }

      return errors;
    },

    getFormParams() {
        let deprecatedInstant = null;
        if (this.userLocal.accessExpires) {
            deprecatedInstant = this.userLocal.accessExpires.toISOString();
        }

        return {
            Presence: {
                ...this.userLocal,
                ownerId: this.selectedOrganisation.id,
                deprecatedInstant: deprecatedInstant
            },
            SendInviteEmail: false,
            MakeSosResponder: false
        };
    },

    async save() {
      let vm = this;

      // Validation
      let validationErrors = this.getValidationErrors();
      if (validationErrors.length) {
        this.$message.error(validationErrors[0]);
        return false;
      }

      vm.isSaving = true;

      try {
        let presence = await presences.addPresence(this.tenantId, this.getFormParams()).then(r => r.data);
        if (this.fileList.length) {
            try {
                await presences.addPresenceThumb(vm.tenantId, presence.id, this.fileList[0]);
            } catch (e) {
                console.log(e);
                vm.$message.error('Error adding thumbnail');
            }
        }
        if (this.responseTeamIds.length) {
            let requests = [];
            _.each(this.responseTeamIds, skillId => {
                requests.push(
                    presences.addSkillAssignment(
                        this.tenantId,
                        this.selectedOrganisation.id,
                        skillId,
                        presence.id
                    )
                )
            });
            try {
                await axios.all(requests);
            } catch (e) {
                console.log(e);
                vm.$message.error('Error adding response teams');
            }
        }
        vm.isSaving = false;
        vm.$message.success('User added successfully');
        vm.$router.push('/admin/users/manage');
      } catch (e) {
        console.log(e);
        vm.isSaving = false;
        vm.$message.error('Error creating new user');
      }
    },
  },

  created() {
    if (!this.selectedOrganisation) {
      this.$message.info("Please select an organisation");
      this.$router.push("/admin");
    }
  },

  computed: {
    ...mapGetters("admin", {
      tenantId: "tenantId",
      selectedOrganisation: "selectedOrganisation",
      isLoading: "isLoading",
    }),
  },
};
</script>

<style scoped lang="scss">
.admin-create-user {
  padding-bottom: 40px;

  .loader {
    background: #fff;
    padding: 50px;
    text-align: center;
  }

  .top-row {
    display: flex;
    .left {
      flex-shrink: 1;
    }
    .right {
      padding-left: 20px;
      flex-grow: 1;
    }
  }

  .form {
    background: #fff;
    padding: 20px;
    border-radius: 6px;
    font-weight: 500;
    border: 1px solid #fff;
  }

  .button-margin-left {
    margin-left: 10px;
  }

  .save-button {
    margin-top: 20px;
  }
}
</style>